// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlAPI: 'https://acesso.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/',
  projeto: 'takeaction', // mercadominio - p25 - geptraining
  // imgLogoTopo: 'https://acesso.naufinance.com/nau/Content/Images/logo-small@1x_p25.png' //P25
  //imgLogoTopo: 'https://acesso.naufinance.com/nau/Content/Images/logo-small@1x_mercadominio.png' //MERCADOMINIO
  imgLogoTopo: '/assets/logo.svg'// 'https://acesso.naufinance.com/nau/Content/Images/takeactionclaro.png' //GEPTRAINING
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
